import React from "react"

export default function Nav(props) {


  return (
    <footer className="mt-10 py-4 bg-black text-white">
        <div className="flex justify-center max-w-2xl mx-auto">
            <small>© Gonza.me | 2022</small>
        </div>
       
    </footer>
  )
}