
import React from "react"

export default function Logo(){
    return (
        <svg version="1.1" class="gonza-logo" xmlns="http://www.w3.org/2000/svg" width="80px" x="0px" y="0px" viewBox="0 0 145 80.9" fill="white">
          
            <path class="st0 logo-bracket1" d="M40.1,40.5c2.6,0.8,3.8,2.4,3.8,6.2V53c0,2.3,1.2,3.6,3.6,3.6h0.7v7.3h-3.3c-7,0-10.6-3.6-10.6-9.8v-7.8
            c0-1.6-0.9-2.3-2.2-2.3h-1.7v-7.3h1.7c1.3,0,2.2-0.7,2.2-2.3v-7.8c0-6.2,3.6-9.8,10.6-9.8h3.3v7.3h-0.7c-2.4,0-3.6,1.3-3.6,3.6
            v6.4C43.9,38.1,42.7,39.7,40.1,40.5z"></path>
            <path class="st0" d="M79.3,38.1H88v14.5c-4.1,2.9-9.6,4.5-14.7,4.5C62.1,57.1,54,49.6,54,38.9s8.2-18.2,19.5-18.2
            c6.6,0,11.9,2.3,15.3,6.4l-6.3,5.7c-2.4-2.7-5.2-3.9-8.5-3.9c-6,0-10,4-10,10c0,5.9,4,10,9.9,10c1.9,0,3.6-0.4,5.4-1.2V38.1z"></path>
            <path class="st0 bracket logo-bracket2" d="M114.5,36.8v7.3h-1.7c-1.3,0-2.2,0.7-2.2,2.3v7.8c0,6.2-3.6,9.8-10.6,9.8h-3.3v-7.3h0.8
            c2.4,0,3.6-1.3,3.6-3.6v-6.4c0-3.8,1.2-5.4,3.8-6.2c-2.6-0.8-3.8-2.4-3.8-6.2v-6.4c0-2.3-1.2-3.6-3.6-3.6h-0.8V17h3.3
            c7,0,10.6,3.6,10.6,9.8v7.8c0,1.6,0.9,2.3,2.2,2.3H114.5z"></path>
        </svg>
    )
}