import React from "react"
import { Helmet } from 'react-helmet'
import Nav from "@components/nav"
import Footer from "@components/footer"

export default function Layout( props ) {
  return (
    <div className={`${props.classes}`}>
      <Helmet title={` ${props.title ? `${props.title} |` : ''} Gonza.me`} defer={false} />
      <Nav />
      <div className="px-4">
        {props.children}
      </div>
      <Footer />
    </div>
  )
}