import React from "react"
import {Link} from 'gatsby'
import Logo from "@components/logo"

export default function Nav(props) {


  return (
    <nav className="mb-10 md:mb-20 py-2 bg-black text-white">
        <div className="flex justify-center max-w-2xl mx-auto">
            <Link to="/" title="Gonza's blog"><Logo /></Link>
        </div>
       
    </nav>
  )
}
